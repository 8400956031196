import React from 'react'
import Layout from '../../components/molecules/layout'
import Seo from '../../components/molecules/seo'
import { PageProps } from 'gatsby'

const Pricing = (props: PageProps) => {
  const title = 'pricing'

  return (
    <>
      <Layout>
        <Seo title={title} />
        <section className="hero is-primary">
          <div className="hero-body">
            <h1 className="page-title">{title}</h1>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="columns">
              <div className="column is-full">
                <main className="content">
                  <h3 className="title is-3">
                    phonetonote is free for general use
                  </h3>
                  <p>
                    phonetonote is free for general use, made possible by Roam
                    Research's plugin revenue system.
                  </p>
                  <p>
                    there is a soft monthly rate limit, which you will be
                    notified of if you approach it.
                  </p>
                  <p>
                    if you would like to further support the project, you can{' '}
                    <a href="https://pro.phonetonote.com/">
                      subscribe for premium email support
                    </a>
                    .
                  </p>
                </main>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default Pricing
